import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const positionsModalRoutes: Routes = [
  {
    path: 'positions',
    outlet: 'modal',
    loadChildren: () => import('./positions-modal-routing-module').then((m) => m.PositionsModalRoutingModule)
  }
];
