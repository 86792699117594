import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, take, map } from 'rxjs/operators';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { LoggingService } from '@logging/logging.service';
import { ActiveAuthenticationStateService } from '@auth-n/state/active-authentication.state-service';

@Injectable({ providedIn: 'root' })
export class CintraPayrollsAccessRouteGuard implements CanActivate {
  constructor(
    private activeSecurityContext: ActiveSecurityContextStateService,
    private authenticationState: ActiveAuthenticationStateService,
    private router: Router,
    private logger: LoggingService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.activeSecurityContext.fetchingActiveUser$.pipe(
      filter((fetching) => fetching === false),
      take(1),
      map(() => {
        if (!this.activeSecurityContext.activeMembership && state.url.includes('under-maintenance')) return true;
        if (!this.authenticationState.isAuthenticated) return false;
        if (this.activeSecurityContext.ensureCintraPayrollsAccess()) {
          return true;
        } else {
          this.logger.trace('CintraPayrollsAccessRouteGuard: No payroll access. Redirecting.');
          return this.router.parseUrl('no-permissions');
        }
      })
    );
  }
}
