import { Injectable } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { LoggingService } from '@logging/logging.service';
import { DateTime } from 'luxon';
import { environment } from '@env/environment';
import { CookieService } from '@utils/cookie.service';

const TIMEOUT = 2 * 60 * 60; // 7200 seconds

@Injectable({ providedIn: 'root' })
export class AbandonmentService {
  private readonly storageKey = 'Abandonment:lastBecameActive';
  private readonly cookieName = 'amaryllis_to';

  constructor(
    private idle: Idle,
    private logger: LoggingService,
    private cookieService: CookieService
  ) {
    this.setupBrowseAwayData();
  }

  isAbandoned(): boolean {
    const storedData = localStorage.getItem(this.storageKey);
    const cookieData = this.cookieService.getCookieValue(this.cookieName);
    let deltaSeconds = 0;

    if (!storedData && !cookieData) {
      this.logger.trace('Abandonment:', 'No stored data found');
      return false;
    }

    const nowMilli = DateTime.utc().toMillis();

    if (storedData) {
      const browseAway = JSON.parse(storedData) as BrowseAwayData;
      const delta = nowMilli - browseAway.timestamp;
      deltaSeconds = delta / 1000;
      if (deltaSeconds < TIMEOUT) return false;
    }

    if (cookieData) {
      const cookieTimestamp = DateTime.fromMillis(parseInt(cookieData, 10)).minus({ hours: 2 }).toMillis();
      const delta = nowMilli - cookieTimestamp;
      deltaSeconds = delta / 1000;
      if (deltaSeconds < TIMEOUT) return false;
    }

    this.logger.trace('Abandonment: difference between stored time and now (seconds)', deltaSeconds, 'configured limit:', TIMEOUT);
    return true;
  }

  setLastBecameActive(): void {
    const now = DateTime.utc();
    this.cookieService.setDomainCookie(this.cookieName, now.plus({ hours: 2 }).toMillis().toString(), environment.cookieDomain);
    localStorage.setItem(this.storageKey, JSON.stringify(new BrowseAwayData(now.toMillis())));
  }

  private setupBrowseAwayData(): void {
    this.idle.onInterrupt.subscribe(() => {
      this.setLastBecameActive();
    });
  }
}

export class BrowseAwayData {
  constructor(public timestamp: number) {}
}
