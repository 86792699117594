import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const payrollModalRoutes: Routes = [
  {
    path: 'payslips',
    outlet: 'right',
    loadChildren: () => import('./payroll-payslip/payroll-payslip.module').then((m) => m.PayrollPayslipModule)
  },
  {
    path: 'pay-summary',
    outlet: 'right',
    loadChildren: () => import('./payroll-pay-summary/payroll-pay-summary.module').then((m) => m.PayrollPaySummaryModule)
  },
  {
    path: 'employee-details',
    outlet: 'modal',
    loadChildren: () => import('./payroll-employee-details/payroll-employee-details.module').then((m) => m.PayrollEmployeeDetailsModule)
  },
  {
    path: 'payroll/one-off-payments',
    outlet: 'modal',
    loadChildren: () =>
      import('./payroll-hub/one-off-payments-modal/payroll-one-off-payments-modal.module').then((m) => m.PayrollOneOffPaymentsModalModule)
  },
  {
    path: 'payroll/one-off-payments-edit',
    outlet: 'modal',
    loadChildren: () =>
      import('./payroll-hub/one-off-payments-edit-modal/payroll-one-off-payments-edit-modal.module').then(
        (m) => m.PayrollOneOffPaymentsEditModalModule
      )
  },
  {
    path: 'payroll/one-off-deductions-edit',
    outlet: 'modal',
    loadChildren: () =>
      import('./payroll-hub/one-off-deductions-edit-modal/payroll-one-off-deductions-edit-modal.module').then(
        (m) => m.PayrollOneOffDeductionsEditModalModule
      )
  },
  {
    path: 'payroll/imports/one-off-payments',
    outlet: 'modal',
    loadChildren: () => import('./payroll-one-off-payments/payroll-one-off-payments.module').then((m) => m.PayrollOneOffPaymentsModule)
  },
  {
    path: 'payroll/imports/salary-and-allowances',
    outlet: 'modal',
    loadChildren: () =>
      import('./payroll-salary-and-allowances/payroll-salary-and-allowances.module').then((m) => m.PayrollSalaryAndAllowancesModule)
  },
  {
    path: 'payroll/:payrollId/group-handover-statuses',
    outlet: 'modal',
    loadChildren: () =>
      import('./payroll-handover/group-handover-statuses-dialog/group-handover-statuses.module').then((m) => m.GroupHandoverStatusesModule)
  },
  {
    path: 'payroll/:payrollId/advanced-reports',
    outlet: 'modal',
    loadChildren: () => import('./payroll-advanced-reports/payroll-advanced-reports.module').then((m) => m.PayrollAdvancedReportsModule)
  },

  {
    path: 'payroll-locked',
    outlet: 'message',
    loadChildren: () => import('./payroll-locked/payroll-locked.module').then((m) => m.PayrollLockedModule)
  },
  {
    path: 'mass-invite',
    outlet: 'modal',
    loadChildren: () =>
      import('@security/users/users-hub/invitations/uninvited/mass-invite-modal/mass-invite-modal.module').then(
        (m) => m.MassInviteModalModule
      )
  },
  {
    path: 'payment-runs',
    outlet: 'modal',
    loadChildren: () => import('./payment-runs/payments-modal/payment-runs-modal.module').then((m) => m.PaymentRunsModalModule)
  },
  {
    path: 'payroll-approval',
    outlet: 'modal',
    loadChildren: () =>
      import('./payment-runs/payroll-approval/approval-modal/payroll-approval-modal.module').then((m) => m.PayrollApprovalModalModule)
  },
  {
    path: 'payroll-approval-confirmation',
    outlet: 'modal',
    loadChildren: () =>
      import('./payment-runs/payroll-approval/approval-confirmation-modal/payroll-approval-confirmation-modal.module').then(
        (m) => m.PayrollApprovalConfirmationModalModule
      )
  },
  {
    path: 'payment-authorisation',
    outlet: 'modal',
    loadChildren: () =>
      import('./payment-runs/payment-authorisation/authorisation-modal/payment-authorisation-modal.module').then(
        (m) => m.PaymentAuthorisationModalModule
      )
  }
];
