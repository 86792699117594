import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const employeesModalRoutes: Routes = [
  {
    path: 'employee-edit',
    outlet: 'secondaryModal',
    loadChildren: () =>
      import('../employees/employee-details/employee-edit-section/employee-edit-section-routing.module').then(
        (m) => m.EmployeeEditSectionRoutingModule
      )
  },
  {
    path: 'employee/holiday-entitlement-details',
    outlet: 'tertiaryModal',
    loadChildren: () =>
      import('../employees/employee-details/holiday-entitlement-routing.module').then((m) => m.HolidayEntitlementRoutingModule)
  },
  {
    path: 'employee/work-pattern',
    outlet: 'tertiaryModal',
    loadChildren: () => import('../employees/employee-details/employee-work-pattern.module').then((m) => m.EmployeeWorkPatternModule)
  },
  {
    path: 'employee/salary-details-old',
    outlet: 'tertiaryModal',
    loadChildren: () => import('../employees/employee-details/employee-salary-details.module').then((m) => m.EmployeeSalaryDetailsModule)
  },
  {
    path: 'employee/salary-details',
    outlet: 'tertiaryModal',
    loadChildren: () => import('../employees/employee-details/employee-salary-details.module').then((m) => m.EmployeeSalaryDetailsModule)
  },
  {
    path: 'employee/dynamic-costing-details',
    outlet: 'secondaryModal',
    loadChildren: () => import('../employees/employee-details/employee-costing-details.module').then((m) => m.EmployeeCostingDetailsModule)
  },
  {
    path: 'employee/rate-details',
    outlet: 'tertiaryModal',
    loadChildren: () => import('../employees/employee-details/employee-rate-details.module').then((m) => m.EmployeeRateDetailsModule)
  },
  {
    path: 'employee/position-details',
    outlet: 'tertiaryModal',
    loadChildren: () =>
      import('../employees/employee-details/employee-position-details.module').then((m) => m.EmployeePositionDetailsModule)
  },
  {
    path: 'employee/inactive-position-details',
    outlet: 'secondaryModal',
    loadChildren: () =>
      import('../employees/employee-details/employee-inactive-position-details.module').then((m) => m.EmployeeInactivePositionDetailsModule)
  },

  {
    path: 'employee/spine-point-details',
    outlet: 'tertiaryModal',
    loadChildren: () =>
      import('../employees/employee-details/employee-spine-point-details.module').then((m) => m.EmployeeSpinePointDetailsModule)
  },
  {
    path: 'employees',
    outlet: 'modal',
    loadChildren: () => import('../employees/new-starters/new-starters.module').then((m) => m.NewStartersModule)
  },

  {
    path: 'employee-invitation-details',
    outlet: 'modal',
    loadChildren: () =>
      import(
        '@security/users/users-hub/invitations/invites/employee-invitation-details-dialog/employee-invitation-details-dialog.module'
      ).then((m) => m.EmployeeInvitationDetailsDialogModule)
  },
  {
    path: 'employee-account-details',
    outlet: 'modal',
    loadChildren: () =>
      import('@security/users/users-hub/users-grid/employee-account-details-modal/employee-account-details-modal.module').then(
        (m) => m.EmployeeAccountDetailsModalModule
      )
  }
];
