import { inject, ModuleWithProviders, NgModule, provideAppInitializer } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApplicationInitialisationService } from './application-initialisation.service';

export function initialiseApplication(applicationInitialisationService: ApplicationInitialisationService): () => Promise<void> {
  return () =>
    new Promise((resolve) => {
      applicationInitialisationService.initialise();
      resolve();
    });
}

@NgModule({
  imports: [RouterModule]
})
export class ApplicationInitialisationModule {
  /**
   * Call this in your Core module to set up amplify-js with the supplied settings.
   */
  static forRoot(): ModuleWithProviders<ApplicationInitialisationModule> {
    return {
      ngModule: ApplicationInitialisationModule,
      providers: [
        provideAppInitializer(() => {
          return initialiseApplication(inject(ApplicationInitialisationService))();
        })
      ]
    };
  }
}
