import { AppHostService } from '../analytics/app-host.service';

export function disableInstallBanner(appHostService: AppHostService) {
  return () => {
    if (appHostService.isNativeApp()) {
      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault();
      });
    }
  };
}
