import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const accessControlModalRoutes: Routes = [
  {
    path: 'pay-summary',
    outlet: 'right',
    loadChildren: () =>
      import('../access-control/groups/group-hub/group-pay-summary/group-pay-summary.module').then((m) => m.GroupPaySummaryModule)
  },
  {
    path: 'employee-details',
    outlet: 'modal',
    loadChildren: () =>
      import('../access-control/groups/group-hub/group-employee-details/group-employee-details.module').then(
        (m) => m.GroupEmployeeDetailsModule
      )
  },
  {
    path: 'payslips',
    outlet: 'right',
    loadChildren: () => import('../access-control/groups/group-hub/group-payslip/group-payslip.module').then((m) => m.GroupPayslipModule)
  },
  {
    path: 'group/set-managers',
    outlet: 'modal',
    loadChildren: () =>
      import('../access-control/groups/group-hub/set-managers-dialog/set-managers.module').then((m) => m.SetManagersModule)
  },
  {
    path: 'group/set-headings',
    outlet: 'modal',
    loadChildren: () =>
      import('../access-control/groups/group-hub/set-group-heading-sets-dialog/set-group-heading-sets.module').then(
        (m) => m.SetGroupHeadingSetsModule
      )
  },
  {
    path: 'group/activate-group',
    outlet: 'modal',
    loadChildren: () =>
      import('../access-control/groups/groups-hub/activate-group-dialog/activate-group.module').then((m) => m.ActivateGroupModule)
  },
  {
    path: 'group/delete-group',
    outlet: 'modal',
    loadChildren: () =>
      import('../access-control/groups/groups-hub/delete-group-dialog/delete-group.module').then((m) => m.DeleteGroupModule)
  },
  {
    path: 'group/new-starter-wizard',
    outlet: 'modal',
    loadChildren: () =>
      import('../access-control/groups/group-hub/new-starter/group-new-starters.module').then((m) => m.GroupNewStartersModule)
  },
  {
    path: 'group/imports/one-off-payments',
    outlet: 'modal',
    loadChildren: () =>
      import('../access-control/groups/group-hub/one-off-payment-import/group-one-off-payments-import.module').then(
        (m) => m.GroupOneOffPaymentsImportModule
      )
  },
  {
    path: 'group/one-off-payments',
    outlet: 'modal',
    loadChildren: () =>
      import('../access-control/groups/group-hub/one-off-payments-modal/group-one-off-payments-modal.module').then(
        (m) => m.GroupOneOffPaymentsModalModule
      )
  },
  {
    path: 'group/one-off-payments-edit',
    outlet: 'modal',
    loadChildren: () =>
      import('../access-control/groups/group-hub/one-off-payments-edit-modal/group-one-off-payments-edit-modal.module').then(
        (m) => m.GroupOneOffPaymentsEditModalModule
      )
  },
  {
    path: 'group/one-off-deductions-edit',
    outlet: 'modal',
    loadChildren: () =>
      import('../access-control/groups/group-hub/one-off-deductions-edit-modal/group-one-off-deductions-edit-modal.module').then(
        (m) => m.GroupOneOffDeductionsEditModalModule
      )
  },
  {
    path: 'heading-set',
    outlet: 'modal',
    loadChildren: () => import('../access-control/heading-sets/modals/heading-sets-modals.module').then((m) => m.HeadingSetsModalsModule)
  },
  {
    path: 'group/:groupId/payroll/:payrollId/advanced-reports',
    outlet: 'modal',
    loadChildren: () =>
      import('../access-control/groups/group-advanced-reports/group-advanced-reports.module').then((m) => m.GroupAdvancedReportsModule)
  },
  {
    path: 'advanced-view',
    outlet: 'modal',
    loadChildren: () =>
      import('../access-control/groups/groups-hub/groups-dashboard/advanced-views/groups-dashboard-advanced-views.module').then(
        (m) => m.GroupsDashboardAdvancedViewsModule
      )
  },
  {
    path: 'bureau-profile',
    outlet: 'modal',
    loadChildren: () => import('../access-control/bureaus/modals/bureau-profiles-modals.module').then((m) => m.BureauProfilesModalsModule)
  },
  {
    path: 'hr-users',
    loadChildren: () => import('../security/users/users-hub/hr-users/hr-users.module').then((m) => m.HrUsersModule)
  }
];
