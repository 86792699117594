import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '@auth-n/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutRouteGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.authenticationService.signOutAndRedirect();
    return true;
  }
}
