import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const paymentsHubModalRoutes: Routes = [
  {
    path: 'bank-files',
    outlet: 'modal',
    loadChildren: () =>
      import('./payment-authorisations/details-modal/payment-authorisation-details.module').then((m) => m.PaymentAuthorisationDetailsModule)
  },
  {
    path: 'payment-authorisation',
    outlet: 'modal',
    loadChildren: () =>
      import('./payment-authorisations/authorisation-modal/payment-hub-authorisation-modal.module').then(
        (m) => m.PaymentHubAuthorisationModalModule
      )
  }
];
