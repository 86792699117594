import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const advancedReportsModalRoutes: Routes = [
  {
    path: 'dynamic-report',
    outlet: 'modal',
    loadChildren: () => import('./dynamic-reporting-modal-routing.module').then((m) => m.DynamicReportingModalRoutingModule)
  },
  {
    path: 'dashboard-report',
    outlet: 'modal',
    loadChildren: () =>
      import('../dashboard-reporting/report-dialog/dashboard-reporting-modal-routing.module').then(
        (m) => m.DashboardReportingModalRoutingModule
      )
  },
  {
    path: 'delete-report',
    outlet: 'modal',
    loadChildren: () =>
      import('./delete-report-definition-dialog/delete-report-definition.module').then((m) => m.DeleteReportDefinitionModule)
  },
  {
    path: 'saveas-report/:reportDefinitionId',
    outlet: 'modal',
    loadChildren: () => import('./report-save-dialog/report-save-dialog.module').then((m) => m.ReportSaveDialogModule)
  },
  {
    path: 'edit-report/:reportDefinitionId',
    outlet: 'modal',
    loadChildren: () => import('./report-save-dialog/report-save-dialog.module').then((m) => m.ReportSaveDialogModule)
  },
  {
    path: 'update-report/:reportDefinitionId',
    outlet: 'modal',
    loadChildren: () => import('./report-update-modal/report-update-modal.module').then((m) => m.ReportUpdateModalModule)
  }
];
