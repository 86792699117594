import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const userModalRoutes: Routes = [
  {
    path: 'change-details',
    outlet: 'modal',
    loadChildren: () => import('./change-details/change-details-modal.module').then((m) => m.ChangeDetailsModalModule)
  },
  {
    path: 'change-password',
    outlet: 'modal',
    loadChildren: () => import('./change-password/change-password-modal.module').then((m) => m.ChangePasswordModalModule)
  },
  {
    path: 'passkeys',
    outlet: 'modal',
    loadChildren: () => import('./passkeys/passkeys.module').then((m) => m.PasskeysModule)
  },
  {
    path: 'employee-invitation-details',
    outlet: 'modal',
    loadChildren: () =>
      import('./users-hub/invitations/invites/employee-invitation-details-dialog/employee-invitation-details-dialog.module').then(
        (m) => m.EmployeeInvitationDetailsDialogModule
      )
  },
  {
    path: 'employee-account-details',
    outlet: 'modal',
    loadChildren: () =>
      import('./users-hub/users-grid/employee-account-details-modal/employee-account-details-modal.module').then(
        (m) => m.EmployeeAccountDetailsModalModule
      )
  },
  {
    path: ':approverId/subordinates',
    outlet: 'tertiaryModal',
    loadChildren: () =>
      import('./users-hub/holiday-approvals/subordinates/subordinates-dialog.module').then((m) => m.SubordinatesDialogModule)
  },
  {
    path: 'grant-payroll-access',
    outlet: 'secondaryModal',
    loadChildren: () =>
      import('./users-hub/users-grid/employee-account-details-modal/grant-payroll-access-modal/grant-payroll-access-modal.module').then(
        (m) => m.GrantPayrollAccessModalModule
      )
  },
  {
    path: 'hr-users',
    loadChildren: () => import('./users-hub/hr-users/hr-users.module').then((m) => m.HrUsersModule)
  },
  {
    path: 'hr-users-details',
    outlet: 'modal',
    loadChildren: () =>
      import('@security/users/users-hub/hr-users/hr-users-details-modal/hr-users-details-modal-routing.module').then(
        (m) => m.HrUsersDetailsModalRoutingModule
      )
  }
];
