import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmptyComponent } from '@design/layout/empty/empty.component';
import { UiVersionRouteGuard } from '@security/authorization/ui-version.route-guard';
import { AnonymousRouteGuard } from '@security/authorization/anonymous.route-guard';
import { ForbiddenComponent } from '@security/authorization/forbidden/forbidden.component';
import { NoPermissionsComponent } from '@security/authorization/no-permissions/no-permissions.component';
import { ResourceNotFoundComponent } from '@design/layout/resource-not-found/resource-not-found.component';
import { payrollModalRoutes } from './payrolls/payroll-modal-routes';
import { employeeHubModalRoutes } from './employee-hub/employee-hub-modal-routes';
import { SignInComponent } from './app-layout/auth-n/sign-in/sign-in.component';
import { IqLockedComponent } from '@security/authorization/IqLocked/IqLocked.component';
import { integrationsModalRoutes } from '@app/integrations-admin/integrations-modal-routes';
import { ResetPasswordComponent } from '@app/app-layout/auth-n/reset-password/reset-password.component';
import { extrasStateResolver } from '@utils/route-utils';
import { employeeLeaveModalRoutes } from '@app/employees/employee-leave/employee-leave-modal-routes';
import { bureauModalRoutes } from '@app/bureau/bureau-modal-routes';
import { calendarModalRoutes } from '@design/components/events-calendar/calendar-modal-routes';
import { adminHubModalRoutes } from '@app/admin-hub/admin-hub-modal-routes';
import { customReportTypesModalRoutes } from '@app/custom-reports-admin/custom-report-types-modal-routes';
import { SsoLandingComponent } from '@auth-n/sso-landing/sso-landing.component';
import { employeesModalRoutes } from '@app/employees/employees-modal-routes';
import { organisationModalRoutes } from '@organisations/organisation-modal-routes';
import { accessControlModalRoutes } from '@app/access-control/access-control-modal-routes';
import { advancedReportsModalRoutes } from '@app/advanced-reports/dynamic-reporting/advanced-reports-modal-routes';
import { userModalRoutes } from '@security/users/user-modal-routes';
import { designSystemModalRoutes } from '@app/design-system/design-system-modal-routes';
import { positionsModalRoutes } from '@app/positions/positions-modal-routes';
import { paymentsHubModalRoutes } from '@app/payments-hub/payments-hub-modal-routes';
import { LogoutRouteGuard } from '@security/active-security/logout.route-guard';
import { CintraPayrollsAccessRouteGuard } from '@security/authorization/cintra-payrolls-access.route-guard';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [UiVersionRouteGuard],
    children: [
      { path: 'redirect', component: EmptyComponent },
      { path: 'forbidden', component: ForbiddenComponent },
      { path: 'locked', component: IqLockedComponent },
      { path: 'no-permissions', component: NoPermissionsComponent },
      { path: 'logout', canActivate: [LogoutRouteGuard], children: [] },
      {
        path: 'under-maintenance',
        outlet: 'top',
        loadChildren: () => import('../framework/under-maintenance/under-maintenance.module').then((m) => m.UnderMaintenanceModule)
      },
      {
        path: 'auth/sign-in',
        pathMatch: 'full',
        redirectTo: '/auth/(top:sign-in)'
      },
      {
        path: 'auth',
        canActivate: [AnonymousRouteGuard],
        children: [
          {
            path: 'sign-in',
            component: SignInComponent,
            outlet: 'top',
            resolve: {
              extrasStateResolver
            }
          },
          {
            path: 'request-reset-password',
            component: ResetPasswordComponent,
            outlet: 'top'
          },
          {
            path: 'reset-password',
            component: ResetPasswordComponent,
            outlet: 'top'
          },
          {
            path: 'sso',
            component: SignInComponent,
            outlet: 'top',
            resolve: {
              extrasStateResolver
            }
          },
          {
            path: 'sso-callback',
            component: SsoLandingComponent,
            outlet: 'top',
            resolve: {
              extrasStateResolver
            }
          }
        ]
      },
      {
        path: 'auth/user-invitation/:id',
        redirectTo: '/user-invitations/(top:register/:id)'
      },
      {
        path: 'user-invitations',
        canActivate: [AnonymousRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./security/user-invitations/user-invitation.module').then((m) => m.UserInvitationModule)
      },
      {
        path: '',
        loadChildren: () => import('./security/my-account/my-account.module').then((m) => m.MyAccountModule)
      },
      ...employeeHubModalRoutes,
      {
        path: 'employee-hub',
        loadChildren: () => import('./employee-hub/employee-hub.module').then((m) => m.EmployeeHubModule)
      },
      ...adminHubModalRoutes,
      ...employeeLeaveModalRoutes,
      {
        path: 'admin-hub',
        loadChildren: () => import('./admin-hub/admin-hub.module').then((m) => m.AdminHubModule)
      },
      ...calendarModalRoutes,
      ...integrationsModalRoutes,
      {
        path: '',
        canActivate: [CintraPayrollsAccessRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./integrations-admin/integrations.module').then((m) => m.IntegrationsModule)
      },
      ...customReportTypesModalRoutes,
      {
        path: '',
        canActivate: [CintraPayrollsAccessRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./custom-reports-admin/custom-report-types.module').then((m) => m.CustomReportTypesModule)
      },
      ...positionsModalRoutes,
      {
        path: '',
        canActivate: [CintraPayrollsAccessRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./positions/positions.module').then((p) => p.PositionsModule)
      },
      { path: 'payroll/hub', redirectTo: 'employers' },
      ...payrollModalRoutes,
      {
        path: '',
        canActivate: [CintraPayrollsAccessRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./payrolls/payrolls.module').then((m) => m.PayrollsModule)
      },
      ...userModalRoutes,
      {
        path: '',
        canActivate: [CintraPayrollsAccessRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('@security/users/user.module').then((m) => m.UserModule)
      },
      {
        path: '',
        canActivate: [CintraPayrollsAccessRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./employers/employer.module').then((m) => m.EmployerModule)
      },
      ...employeesModalRoutes,
      {
        path: '',
        canActivate: [CintraPayrollsAccessRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./employees/employees.module').then((m) => m.EmployeesModule)
      },
      ...paymentsHubModalRoutes,
      {
        path: '',
        canActivate: [CintraPayrollsAccessRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./payments-hub/payments-hub.module').then((m) => m.PaymentsHubModule)
      },
      ...organisationModalRoutes,
      {
        path: '',
        canActivate: [CintraPayrollsAccessRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./organisations/organisation.module').then((m) => m.OrganisationModule)
      },
      ...accessControlModalRoutes,
      {
        path: '',
        canActivate: [CintraPayrollsAccessRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./access-control/access-control.module').then((m) => m.AccessControlModule)
      },
      {
        path: '',
        loadChildren: () => import('./access-control/bureaus/bureau-profiles.module').then((m) => m.BureauProfilesModule)
      },
      ...bureauModalRoutes,
      {
        path: 'bureau',
        loadChildren: () => import('./bureau/bureau.module').then((m) => m.BureauModule)
      },
      ...advancedReportsModalRoutes,
      {
        path: '',
        loadChildren: () => import('./advanced-reports/dynamic-reporting/advanced-reports.module').then((m) => m.AdvancedReportsModule)
      },
      {
        path: 'hrlog',
        canActivate: [CintraPayrollsAccessRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./hrlog/cintrahrlog.module').then((m) => m.CintraHrLogModule)
      },
      ...designSystemModalRoutes,
      {
        path: '',
        loadChildren: () => import('./design-system/design-system-docs.module').then((m) => m.DesignSystemDocsModule)
      },
      {
        path: '',
        loadChildren: () => import('./admin-area/admin-area.module').then((m) => m.AdminAreaModule)
      },
      {
        path: '',
        loadChildren: () => import('./test-support/test-support.module').then((m) => m.TestSupportModule)
      },
      {
        path: '',
        canActivate: [CintraPayrollsAccessRouteGuard],
        runGuardsAndResolvers: 'always',
        loadChildren: () => import('./messaging/messaging.module').then((m) => m.MessagingModule)
      },
      { path: '', component: EmptyComponent },
      { path: '**', component: ResourceNotFoundComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
