import { ErrorHandler, inject, NgModule, Optional, provideAppInitializer, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { HotjarService, initHotjarFunction, MockHotjarService, MockSegmentAnalyticsService, SegmentAnalyticsService } from '@analytics';
import { JwtInterceptor } from '@security/authorization/jwt.interceptor';
import { AuthorisationModule } from '@security/authorization/authorisation.module';
import { ApplicationInitialisationModule } from '@auth-n/application-initialisation.module';
import { IdleActivityModule } from '../framework/idle-activity/idle-activity.module';
import { FeatureFlagModule } from '@feature-flags/feature-flag.module';
import { LoggingModule } from '@logging/logging.module';
import { environment } from '@env/environment';
import { PayrollAccessDeniedInterceptor } from '@security/authorization/payroll-access-denied.interceptor';
import { UnderMaintenanceInterceptor } from '../framework/under-maintenance/under-maintenance.interceptor';
import { FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';
import { ConfigCatFeatureFlagService } from '@feature-flags/config-cat/config-cat-feature-flag.service';
import { ActiveUserService, setUpActiveUserService } from '@security/active-security/active-user.service';
import {
  AccountExpiringBannerService,
  setUpAccountExpiringBannerService
} from './app-layout/banners/account-expiring/account-expiring-banner.service';
import { provideZxvbnServiceForPSM } from 'angular-password-strength-meter/zxcvbn';
import { CustomErrorHandler } from '@logging/custom-error.handler';
import { ExtendedHttpClient } from '@logging/extended-http-client';
import { initCookieYesFunction } from '../framework/cookie-policy/cookie-policy-init';
import { provideServiceWorker } from '@angular/service-worker';
import { disableInstallBanner } from '../framework/pwa/pwa-service';
import { AppHostService } from '../framework/analytics/app-host.service';
import { provideNgIdle } from '@ng-idle/core';

/**
 * Core load-once module.
 */
@NgModule({
  imports: [
    environment.noAnimations ? NoopAnimationsModule : BrowserAnimationsModule,
    LoggingModule.forRoot(environment.loggingConfig),
    ApplicationInitialisationModule.forRoot(),
    IdleActivityModule.forRoot(environment.idleActivityConfig),
    AuthorisationModule,
    FeatureFlagModule
  ],
  providers: [
    provideNgIdle(),
    provideZxvbnServiceForPSM(),
    { provide: HttpClient, useClass: ExtendedHttpClient },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnderMaintenanceInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PayrollAccessDeniedInterceptor,
      multi: true
    },
    {
      provide: SegmentAnalyticsService,
      useClass: environment.analyticsDisabled ? MockSegmentAnalyticsService : SegmentAnalyticsService
    },
    {
      provide: HotjarService,
      useClass: environment.analyticsDisabled ? MockHotjarService : HotjarService
    },
    provideAppInitializer(initHotjarFunction()),
    provideAppInitializer(initCookieYesFunction()),
    provideAppInitializer(() => {
      return disableInstallBanner(inject(AppHostService))();
    }),
    provideAppInitializer(() => {
      return setUpActiveUserService(inject(ActiveUserService), inject(SegmentAnalyticsService))();
    }),
    provideAppInitializer(() => {
      return setUpAccountExpiringBannerService(inject(AccountExpiringBannerService))();
    }),
    { provide: FeatureFlagsServiceInjectionToken, useClass: ConfigCatFeatureFlagService },
    provideHttpClient(withInterceptorsFromDi()),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !environment.pwaDisabled,
      registrationStrategy: 'registerWhenStable:30000'
    })
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules once in the AppModule.');
    }
  }
}
