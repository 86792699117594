import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const designSystemModalRoutes: Routes = [
  {
    path: 'test-form',
    outlet: 'modal',
    loadChildren: () => import('./snapshot-test-forms/test-form-modals.module').then((m) => m.TestFormModalsModule)
  }
];
