import { Routes } from '@angular/router';

// modal routes only work at the root level...

export const organisationModalRoutes: Routes = [
  {
    path: 'organisation/edit',
    outlet: 'modal',
    loadChildren: () =>
      import('@organisations/organisation-hub/organisation-edit/organisation-edit.module').then((m) => m.OrganisationEditModule)
  },
  {
    path: 'organisation/create',
    outlet: 'modal',
    loadChildren: () =>
      import('@organisations/organisation-hub/organisation-edit/organisation-edit.module').then((m) => m.OrganisationEditModule)
  },
  {
    path: 'sso-configuration/:ssoConfigId',
    outlet: 'modal',
    loadChildren: () =>
      import('@organisations/customer-settings/sso-settings/edit-modal/sso-configuration-modal.module').then(
        (m) => m.SsoConfigurationModalModule
      )
  },
  {
    path: 'sso-configuration',
    outlet: 'modal',
    loadChildren: () =>
      import('@organisations/customer-settings/sso-settings/edit-modal/sso-configuration-modal.module').then(
        (m) => m.SsoConfigurationModalModule
      )
  }
];
